<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple</v-icon> Entry Owners
                 <v-spacer/>
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="user={}, state='new', form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add Owner</v-btn>
               </v-toolbar>
               <v-card-text>
                 
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="owners"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon  x-small left color="warning" @click="owner=item, state='update', form=true" >mdi-pencil</v-icon>
                            {{ item.name }}</td>
                            <td>{{item.address}}</td>
                            <td>{{item.contact_no}}</td>
                            <td>{{ item.created_at | moment("YYYY-MM-DD HH:mm A") }}</td>
                           
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
    <va-owner-form :show="form" :owner_id="owner.id" :state="state" @DialogEvent="formEvent" />
    <!-- <va-area-form :show="areaform" :area="area" :state="state" @DialogEvent="afEvent" /> -->
 
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        owner: {},
        search:'',
        owners:[],
        headers: [
            { text: 'Name', value:"name"},
            { text: 'Address', value:"address"},
            { text: 'Contact #', value:"contact_no"},
            { text: 'Registered', value:"registered_at"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
     
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.owner = data.owner
              this.Save()
          }
      },

      getItems: function() { 
        this.$http.get("owner/list").then(response => {
            response.data.items != null?this.owners = response.data.items:this.owners =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'owner/add'
        this.state != 'new'?method='owner/update':method = 'owner/add'
      console.log(this.owner)
        this.$http.post(method, this.owner).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>